import React from "react";
import { Typography, Layout } from "antd";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const TermsAndConditions = () => {
  return (
    <Layout style={{ padding: "20px 50px" }}>
      <Content
        style={{
          backgroundColor: "#fff",
          padding: "30px",
          borderRadius: "8px",
        }}
      >
        <Typography>
          <Title level={2}>Términos y Condiciones</Title>

          <Paragraph>
            Estos términos y condiciones describen las reglas y regulaciones
            para el uso del sitio web del Hotel Campestre - Rinconcito de
            Chigna.
          </Paragraph>

          <Title level={3}>1. Introducción</Title>
          <Paragraph>
            Al acceder a este sitio web, asumimos que aceptas estos términos y
            condiciones. No continúes usando el sitio web si no aceptas todos
            los términos y condiciones establecidos en esta página.
          </Paragraph>

          <Title level={3}>2. Cookies</Title>
          <Paragraph>
            Empleamos el uso de cookies. Al acceder a Hotel Campestre -
            Rinconcito de Chigna, aceptas el uso de cookies de acuerdo con
            nuestra política de privacidad.
          </Paragraph>

          <Title level={3}>3. Licencia</Title>
          <Paragraph>
            A menos que se indique lo contrario, Hotel Campestre - Rinconcito de
            Chigna y/o sus licenciantes poseen los derechos de propiedad
            intelectual de todo el material en el sitio web. Todos los derechos
            de propiedad intelectual están reservados.
          </Paragraph>

          <Title level={3}>4. Uso Aceptable</Title>
          <Paragraph>
            No debes usar este sitio web de manera que cause, o pueda causar,
            daño al sitio web o interferencia con el acceso a él, o de cualquier
            manera que sea ilegal, fraudulenta o dañina.
          </Paragraph>

          <Title level={3}>5. Contenido del Usuario</Title>
          <Paragraph>
            Las áreas de este sitio web ofrecen la oportunidad para que los
            usuarios publiquen e intercambien opiniones e información. Hotel
            Campestre - Rinconcito de Chigna no filtra, edita, publica ni revisa
            los comentarios antes de su presencia en el sitio web.
          </Paragraph>

          <Title level={3}>6. Limitación de Responsabilidad</Title>
          <Paragraph>
            Hotel Campestre - Rinconcito de Chigna no será responsable de ningún
            daño que surja o esté relacionado con el uso de este sitio web.
          </Paragraph>

          <Title level={3}>7. Modificaciones a los Términos</Title>
          <Paragraph>
            Hotel Campestre - Rinconcito de Chigna puede revisar estos términos
            y condiciones en cualquier momento. Al utilizar este sitio web, se
            espera que revises estos términos regularmente.
          </Paragraph>

          <Title level={3}>8. Ley Aplicable</Title>
          <Paragraph>
            Estos términos y condiciones se rigen e interpretan de acuerdo con
            las leyes locales. Usted se somete a la jurisdicción no exclusiva de
            los tribunales del país para la resolución de cualquier disputa.
          </Paragraph>

          <Title level={3}>9. Contacto</Title>
          <Paragraph>
            Si tienes alguna pregunta sobre estos términos y condiciones,
            contáctanos en rinconcitodechigna@gmail.com o visita nuestra página
            de contacto.
          </Paragraph>
        </Typography>
      </Content>
    </Layout>
  );
};

export default TermsAndConditions;
