import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

// PRODUCTION
const firebaseConfig = {
  apiKey: "AIzaSyCUwwayGMY5AbZf9FeqHa7NsmaxIWtZr_o",
  authDomain: "rinconcito-de-chigna.firebaseapp.com",
  projectId: "rinconcito-de-chigna",
  storageBucket: "rinconcito-de-chigna.appspot.com",
  messagingSenderId: "417296455792",
  appId: "1:417296455792:web:6f9ec7fab58af4a69e7cb5",
  measurementId: "G-TKMWT7GRD9"
};

// TEST
// const firebaseConfig = {
//   apiKey: "AIzaSyDSjwbnCz75eLbJiYDz6T6rea97UkOoQgY",
//   authDomain: "hotel-campestre-a85b2.firebaseapp.com",
//   projectId: "hotel-campestre-a85b2",
//   storageBucket: "hotel-campestre-a85b2.appspot.com",
//   messagingSenderId: "217543152637",
//   appId: "1:217543152637:web:94568bde15dc992bd27f94",
//   measurementId: "G-QGV30MQVGR"
// };

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const storage = app.storage();
const firestore = app.firestore();


export { auth, storage, firestore };
