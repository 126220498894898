import React, { useEffect, useState } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { v4 as uuidv4 } from "uuid";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


const InitPayment = ({ handleSubmit, reservationData, previousStep }) => {
  const [clientSecret, setClientSecret] = useState('');
  const [folio, setFolio] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const generateFolio = async () => {
    const now = new Date();
    const day = now.getDate().toString().padStart(2, "0");
    const hour = now.getHours().toString().padStart(2, "0");
    const minute = now.getMinutes().toString().padStart(2, "0");
    const second = now.getSeconds().toString().padStart(2, "0");
  
    const uuid = uuidv4().split("-")[0];
  
    return `HC-${day}${hour}${minute}${second}${uuid}`;
  };

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const folio = await generateFolio();
        setFolio(folio);
        const response = await fetch(process.env.REACT_APP_URL + '/crear-pago', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: (reservationData.subtotal + (reservationData.subtotal * 0.16)) * 100,
            currency: "mxn",
            folio: folio,
            customerEmail: reservationData.customerEmail,
            customerName: reservationData.customerName,
            customerPhone: reservationData.customerPhone,
          })
        });

        const data = await response.json();

        if (!data.success || !data.info.client_secret) {
          throw new Error("Inténtelo de nuevo.");
        }

        setClientSecret(data.info.client_secret);
      } catch (error) {
        console.error("Error al obtener clientSecret:", error);
        setError(error.message);
      } finally {
        setLoading(false); 
      }
    };

    fetchClientSecret();
  }, [reservationData]);

  const options = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    },
    clientSecret,
  };

  if (loading) {
    return <div>Cargando información de pago...</div>;
  }

  if (error) {
    return (
      <div>
        <p>Hubo un problema al iniciar el formulario de pago: {error}</p>
        <button onClick={() => previousStep()}>Intentar de nuevo</button>
      </div>
    );
  }


  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm
        handleSubmit={handleSubmit}
        total={reservationData.subtotal + (reservationData.subtotal * 0.16)}
        folio={folio}
      />
    </Elements>
  );
};

export default InitPayment;
