import {
  FieldTimeOutlined,
  MailOutlined,
  MessageOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { Button, Card, Col, Form, Input, message, Row } from "antd";
import React, { useState } from "react";
import Logo from "./imgs/logo.png";
import { firestore } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

const Contact = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await addDoc(collection(firestore, "contacto"), {
        nombre: values.name,
        email: values.email,
        mensaje: values.message,
        asunto: values.subject,
        creacion: new Date(),
      });
      message.success("Hemos recibido tu mensaje, nos pondremos en contacto contigo!");
    } catch (error) {
      message.error("Error al enviar mensaje, intenta de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  const defaultCenter = {
    lat: 19.84321757543641,
    lng: -98.00240567934561,
  };

  const mapStyles = [
    {
      featureType: "all",
      elementType: "labels",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ visibility: "on" }],
    },
  ];

  const googleMapsLink = `https://www.google.com/maps/dir/?api=1&destination=${defaultCenter.lat},${defaultCenter.lng}`;

  return (
    <Row gutter={24} style={{ margin: 0, padding: 0, marginTop: 70 }}>
      <Col xs={23} sm={23} md={23} lg={10} offset={1}>
        <Card title="Contáctanos" style={{ margin: "auto" }}>
          <Form
            name="contact_form"
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{ remember: true }}
          >
            <Form.Item
              name="name"
              label="Nombre"
              rules={[
                { required: true, message: "Por favor ingresa tu nombre!" },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Nombre" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Correo Electrónico"
              rules={[
                {
                  required: true,
                  message: "Por favor ingresa tu correo electrónico!",
                },
                {
                  type: "email",
                  message: "El correo electrónico no es válido!",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined />}
                placeholder="Correo Electrónico"
              />
            </Form.Item>
            <Form.Item
              name="subject"
              label="Asunto"
              rules={[
                { required: true, message: "Por favor ingresa el asunto!" },
              ]}
            >
              <Input prefix={<FieldTimeOutlined />} placeholder="Asunto" />
            </Form.Item>
            <Form.Item
              name="message"
              label="Mensaje"
              rules={[
                { required: true, message: "Por favor ingresa tu mensaje!" },
              ]}
            >
              <Input.TextArea
                prefix={<MessageOutlined />}
                rows={3}
                placeholder="Mensaje"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Enviar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col xs={23} sm={23} md={23} lg={12} offset={1} className="ubication">
        <Card title="Ubicación" style={{ height: "100%" }}>
          <GoogleMap
            mapContainerStyle={{ height: "380px", width: "100%" }}
            center={defaultCenter}
            zoom={17}
            options={{ styles: mapStyles }}
          >
            <MarkerF
              position={defaultCenter}
              icon={{url: Logo, scaledSize: new window.google.maps.Size(50, 50)}}
            />
          </GoogleMap>
          <Button
            type="primary"
            style={{ marginTop: "10px" }}
            href={googleMapsLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Cómo llegar
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default Contact;
