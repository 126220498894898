import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Rooms from "./components/Rooms";
import { Button, Layout, Menu } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { HomeOutlined, WhatsAppOutlined } from "@ant-design/icons";
import Contact from "./components/Contact";
import Title from "antd/es/typography/Title";
import Services from "./components/Services";
import BookingModal from "./components/BookingModal";
import { useState } from "react";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import { LoadScript } from "@react-google-maps/api";

function App() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const API_KEY = process.env.REACT_APP_API_KEY;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Router>
        <Layout>
          <Header
            style={{
              position: "fixed",
              zIndex: 1,
              width: "100%",
              height: "10vh",
            }}
          >
            <div
              className="logo"
              style={{
                color: "#fff",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Link to="/">
                <Title level={3} style={{ color: "goldenrod" }}>
                  Hotel Campestre
                </Title>
              </Link>
              <div>
                <Button
                  type="link"
                  href="https://wa.me/527971010510"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#25D366",
                  }}
                  size="large"
                  icon={<WhatsAppOutlined />}
                ></Button>
              </div>
            </div>
            <Menu
              theme="light"
              mode="horizontal"
              defaultSelectedKeys={["1"]}
              className="menu"
              breakpoint="lg"
              collapsedWidth="0"
            >
              <Menu.Item>
                <Button onClick={showModal} style={{ height: "100%" }}>
                  Reservar
                </Button>
              </Menu.Item>
              <Menu.Item key="1" icon={<HomeOutlined />}>
                <Link to="/">Inicio</Link>
              </Menu.Item>
              <Menu.Item key="2"></Menu.Item>
              <Menu.Item key="3">
                <Link to="/habitaciones">Habitaciones</Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to="/servicios">Servicios</Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Link to="/contacto">Contáctanos</Link>
              </Menu.Item>
            </Menu>
          </Header>
          <Layout style={{ marginTop: 78 }}>
            <Content className="hotel-content">
              <LoadScript googleMapsApiKey={API_KEY}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/habitaciones" element={<Rooms />} />
                  <Route path="/servicios" element={<Services />} />
                  <Route path="/contacto" element={<Contact />} />
                  <Route
                    path="/policita-de-privacidad"
                    element={<PrivacyPolicy />}
                  />
                  <Route
                    path="/terminos-y-condiciones"
                    element={<TermsAndConditions />}
                  />
                </Routes>
              </LoadScript>
            </Content>
            <Footer
              style={{
                textAlign: "center",
                padding: "10px 20px",
                lineHeight: 0.5,
                backgroundColor: "#f0f2f5",
              }}
            >
              <div>
                <p>Hotel Campestre - Rinconcito de Chigna ©2024</p>
                <Link
                  to="/policita-de-privacidad"
                  style={{ marginRight: "15px", color: "#1890ff" }}
                >
                  Políticas de Privacidad
                </Link>

                <Link to="/terminos-y-condiciones" style={{ color: "#1890ff" }}>
                  Términos y Condiciones
                </Link>
              </div>
            </Footer>
          </Layout>
        </Layout>
        <BookingModal visible={isModalVisible} onCancel={handleCancel} />
      </Router>
    </>
  );
}

export default App;
