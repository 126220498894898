import React from "react";
import { Card, Col, Row, Avatar } from "antd";
import EstacionamientoIcon from "./imgs/parking.svg";
import RestauranteIcon from "./imgs/restaurant.svg";
import SalonIcon from "./imgs/salon.svg";
import TransportIcon from "./imgs/transport.svg";
import WifiIcon from "./imgs/wifi.svg";
import PalapaIcon from "./imgs/palapa.svg";
import WaterIcon from "./imgs/water.svg";
import CCTVIcon from "./imgs/cctv.svg";

const serviceData = [
  {
    title: "Estacionamiento",
    description: "Amplio estacionamiento para nuestros huéspedes.",
    imageUrl: EstacionamientoIcon,
    detailPath: "/estacionamiento",
  },
  {
    title: "Restaurante",
    description: "Disfruta de nuestra deliciosa gastronomía.",
    imageUrl: RestauranteIcon,
    detailPath: "/restaurante",
  },
  {
    title: "Transporte",
    description: "Servicio de transporte para tu comodidad.",
    imageUrl: TransportIcon,
    detailPath: "/transporte",
  },
  {
    title: "Internet",
    description: "Conexión Wi-Fi gratuita en todo el hotel.",
    imageUrl: WifiIcon,
    detailPath: "/internet",
  },
  {
    title: "Agua Caliente",
    description: "Agua caliente disponible las 24 horas.",
    imageUrl: WaterIcon,
    detailPath: "/agua-caliente",
  },
  {
    title: "Palapa (Bar)",
    description: "Relájate en nuestra palapa con bar.",
    imageUrl: PalapaIcon,
    detailPath: "/palapa",
  },
  {
    title: "Salón de Eventos",
    description: "Espacios para tus eventos y reuniones.",
    imageUrl: SalonIcon,
    detailPath: "/salon-de-eventos",
  },
  {
    title: "Seguridad (CCTV)",
    description: "Sistema de cámaras de seguridad para tu tranquilidad.",
    imageUrl: CCTVIcon,
    detailPath: "/seguridad",
  },
];

// const services = [
//   { icon: <CarOutlined />, title: "Estacionamiento" },
//   { icon: <CoffeeOutlined />, title: "Restaurante" },
//   { icon: <WifiOutlined />, title: "Internet" },
//   { icon: <FireOutlined />, title: "Agua Caliente" },
//   { icon: <TeamOutlined />, title: "Salón de Eventos" },
//   { icon: <HomeOutlined />, title: "Palapa (Bar)" },
//   { icon: <CalendarOutlined />, title: "Transporte" },
// ];

const Services = () => {
  return (
    <div className="container">
      <Row gutter={24}>
        {serviceData.map((service, index) => (
          <Col xs={24} md={8} lg={6} key={index} style={{ marginBottom: "20px", textAlign: 'center' }}>
            <Card
              hoverable
              cover={
                <Avatar
                  size="large"
                  src={service.imageUrl}
                  style={{ width: "100px", margin: 'auto', height: "100px", borderRadius: "50%", marginTop: 20, textAlign:'center', verticalAlign: 'center' }}
                >
                </Avatar>
              }
            >
              <Card.Meta
                title={service.title}
                description={service.description}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Services;
