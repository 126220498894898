import React from "react";
import { Avatar, Card, Col, Row, Typography } from "antd";
import BasilicaImg from "./imgs/basilica.jpg";
import AlmoloyaImg from "./imgs/laguna-almoloya.jpg";
import TulimanImg from "./imgs/tuliman.jpg";
import QuetzalapanImg from "./imgs/quetzalapan.jpg";
import BrisasImg from "./imgs/brisas.jpg";
import JoyaImg from "./imgs/joya.jpg";
import KioscoImg from "./imgs/kiosco.jpeg";
import CentroImg from "./imgs/centro.jpg";
import TermalesImg from "./imgs/termales.jpg";

const announcements = [
  {
    text: "A 10min Basílica de la Inmaculada",
    imageUrl: BasilicaImg
  },
  {
    text: "A 10min Laguna Almoloya",
    imageUrl: AlmoloyaImg
  },
  {
    text: "A 10min de los baños termales",
    imageUrl: TermalesImg
  },
  {
    text: "A 10min del centro de Chignahuapan",
    imageUrl: CentroImg
  },
  {
    text: "A 15min Kiosco Mudéjar",
    imageUrl: KioscoImg
  },
  {
    text: "A 15min Cascada Tulimán",
    imageUrl: TulimanImg
  },
  {
    text: "A 15min Cascada Salto de Quetzalapan",
    imageUrl: QuetzalapanImg
  },
  {
    text: "A 15min Cascada Las Brisas",
    imageUrl: BrisasImg
  },
  {
    text: "A 20min Cascada La Joya",
    imageUrl: JoyaImg
  },
];

const Anuncios = () => (
  <Row gutter={16}>
    {announcements.map((announcement, index) => (
      <Col xs={24} md={12} lg={8} key={index}>
        <Card
          bordered={false}
          style={{ backgroundColor: "rgba(255, 255, 255, 0.4)", color: "#000", margin: 8 }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar 
              src={announcement.imageUrl} 
              size={82} 
              style={{ marginRight: 16 }} 
            />
            <Typography.Text style={{ color: "#000", fontWeight: "bold" }}>
              {announcement.text}
            </Typography.Text>
          </div>
        </Card>
      </Col>
    ))}
  </Row>
);

export default Anuncios;
