import React from "react";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        padding: "0",
        maxWidth: "1200px",
        margin: "0 auto",
        marginTop: 80,
        lineHeight: "1.6",
        textAlign: "justify",
      }}
    >
      <h1>Política de Privacidad</h1>
      <p>
        En Hotel Campestre, valoramos y respetamos su privacidad. Esta
        Política de Privacidad describe cómo recopilamos, usamos, y protegemos
        su información personal.
      </p>

      <h2>1. Información que Recopilamos</h2>
      <p>Podemos recopilar varios tipos de información, incluyendo:</p>
      <ul>
        <li>
          <strong>Información Personal:</strong> Nombre, dirección de correo
          electrónico, número de teléfono, etc.
        </li>
        <li>
          <strong>Información de Reservas:</strong> Detalles sobre su estancia,
          preferencias de habitación, etc.
        </li>
        <li>
          <strong>Información Financiera:</strong> Información de pago y
          transacciones.
        </li>
        <li>
          <strong>Información Técnica:</strong> Dirección IP, tipo de navegador,
          sistema operativo, etc.
        </li>
      </ul>

      <h2>2. Uso de la Información</h2>
      <p>Utilizamos la información recopilada para:</p>
      <ul>
        <li>Procesar y gestionar sus reservas.</li>
        <li>Personalizar su experiencia durante su estancia.</li>
        <li>Enviar promociones y ofertas especiales.</li>
        <li>Mejorar nuestros servicios y el sitio web.</li>
        <li>Cumplir con obligaciones legales y normativas.</li>
      </ul>

      <h2>3. Compartición de Información</h2>
      <p>
        No vendemos, alquilamos ni intercambiamos su información personal. Sin
        embargo, podemos compartir su información con:
      </p>
      <ul>
        <li>
          <strong>Proveedores de Servicios:</strong> Empresas que nos ayudan a
          operar el sitio web o proporcionar servicios a nuestros clientes.
        </li>
        <li>
          <strong>Autoridades Legales:</strong> Cuando sea requerido por la ley
          o para proteger nuestros derechos legales.
        </li>
      </ul>

      <h2>4. Protección de la Información</h2>
      <p>
        Implementamos medidas de seguridad para proteger su información
        personal, como el uso de tecnologías de encriptación y acceso
        restringido a la información.
      </p>

      <h2>5. Sus Derechos</h2>
      <p>
        Usted tiene derecho a acceder, rectificar, y eliminar su información
        personal. También puede optar por no recibir comunicaciones de marketing
        en cualquier momento.
      </p>

      <h2>6. Cookies y Tecnologías Similares</h2>
      <p>
        Utilizamos cookies para mejorar su experiencia en nuestro sitio web.
        Puede configurar su navegador para rechazar las cookies, pero esto puede
        afectar la funcionalidad del sitio.
      </p>

      <h2>7. Cambios en la Política de Privacidad</h2>
      <p>
        Podemos actualizar esta Política de Privacidad en cualquier momento. Las
        modificaciones serán efectivas a partir de su publicación en el sitio
        web.
      </p>

      <h2>8. Contacto</h2>
      <p>
        Si tiene preguntas o inquietudes sobre nuestra Política de Privacidad, no dude en contactarnos a través de rinconcitodechigna@gmail.com o +52 797 101 0510.
      </p>

      <p>Última actualización: 18 de Agosto del 2024</p>
    </div>
  );
};

export default PrivacyPolicy;
