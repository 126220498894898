import React, { useState } from 'react';
import { Card, Button, Col, Row, Carousel } from 'antd';
import BookingModal from './BookingModal';
import Title from "antd/es/typography/Title";
import SimpleRoom1 from "./imgs/simple-room.jpg"
import DobleRoom1 from "./imgs/doble-room.jpeg"

const roomData = [
  {
    title: 'Habitación Simple $650.00 / noche',
    description: 'Una habitación confortable para una persona.',
    images: [
      SimpleRoom1
    ],
  },
  {
    title: 'Habitación Doble $950.00 / noche',
    description: 'Una habitación espaciosa para dos personas.',
    images: [
      DobleRoom1
    ],
  }
];

const Rooms = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const showModal = (room) => {
    setSelectedRoom(room);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRoom(null);
  };

  return (
    <div className='container'>
      <Title level={2}>Nuestras Habitaciones</Title>
      <Row gutter={24}>
        {roomData.map((room, index) => (
          <Col xs={24} md={8} lg={6} key={index} style={{ marginBottom: '20px' }}>
            <Card
              hoverable
              cover={
                <Carousel autoplay autoplaySpeed={3000}>
                  {room.images.map((image, idx) => (
                    <div key={idx}>
                      <img src={image} alt={room.title} style={{ width: '100%', height: '200px' }} />
                    </div>
                  ))}
                </Carousel>
              }
            >
              <Card.Meta title={room.title} description={room.description} />
              <Button
                type="primary"
                style={{ marginTop: '10px' }}
                onClick={() => showModal(room)}
              >
                Reservar
              </Button>
            </Card>
          </Col>
        ))}
      </Row>
      {selectedRoom && (
        <BookingModal
          visible={isModalVisible}
          onCancel={handleCancel}
          room={selectedRoom}
        />
      )}
    </div>
  );
};

export default Rooms;
