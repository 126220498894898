import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  DatePicker,
  Spin,
  Steps,
  Card,
  message,
  Checkbox,
  Select,
  Radio,
} from "antd";
import moment from "moment";
import { firestore } from "../firebase";
import PhoneInput from "react-phone-input-2";
import InitPayment from "./InitPayment";

const { RangePicker } = DatePicker;
const { Step } = Steps;

const BookingModal = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [roomAvailability, setRoomAvailability] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [reservationData, setReservationData] = useState({});
  const [requiresInvoice, setRequiresInvoice] = useState(false);
  const [customerType, setCustomerType] = useState(null);
  const { Option } = Select;

  const checkRoomAvailability = async (dates) => {
    setLoading(true);
    const db = firestore;
    const startDate = dates[0].toDate();
    const endDate = dates[1].toDate();

    try {
      const month = moment(startDate).format("YYYY-MM");
      const reservacionesRef = db
        .collection("reservaciones")
        .doc(month)
        .collection("reservaciones");

      const snapshot = await reservacionesRef
        .where("startDate", "<=", endDate)
        .where("endDate", ">=", startDate)
        .get();

      let reservedSimple = 0;
      let reservedDouble = 0;
      for (const doc of snapshot.docs) {
        const data = doc.data();
        reservedSimple += parseInt(data.simpleRooms);
        reservedDouble += parseInt(data.dobleRooms);
      };

      const totalSimpleRooms = 4;
      const totalDoubleRooms = 8; 
      
      const availableSimple = totalSimpleRooms - reservedSimple;
      const availableDouble = totalDoubleRooms - reservedDouble;

      setRoomAvailability({
        simple: {
          available: availableSimple > 0 ? availableSimple : 0,
          price: 650,
        },
        doble: {
          available: availableDouble > 0 ? availableDouble : 0,
          price: 950,
        },
      });
    } catch (error) {
      console.error("Error checking room availability: ", error);
      message.error("Error al comprobar la disponibilidad de habitaciones.");
    } finally {
      setLoading(false);
    }
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleClientInfoSubmit = (values) => {
    setReservationData({ ...reservationData, ...values });
    setCurrentStep(currentStep + 1);
  };

  const handleIncoiceForm = (values) => {
    const subtotal =
      reservationData.simpleRooms * roomAvailability.simple.price +
      reservationData.dobleRooms * roomAvailability.doble.price;
    setReservationData({ ...reservationData, ...values, subtotal: subtotal });
    setCurrentStep(currentStep + 1);
  };

  const handleDateSelection = (values) => {
    setReservationData({ ...reservationData, ...values });
    setCurrentStep(currentStep + 1);
  };

  const handleInfoSelection = () => {
    setReservationData({ ...reservationData });
    setCurrentStep(currentStep + 1);
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length > 0) {
      if (dates && dates.length === 2) {
        checkRoomAvailability(dates);
        setSelectedDates(dates);
      }
    }
  };

  const handleSubmit = async (folio) => {
    const db = firestore;
    const month = moment(selectedDates[0].toDate()).format("YYYY-MM");
    try {
      await db
        .collection("reservaciones")
        .doc(month)
        .collection("reservaciones")
        .add({
          ...reservationData,
          folio,
          invoice: requiresInvoice === true ? true : false,
          startDate: selectedDates[0].toDate(),
          endDate: selectedDates[1].toDate(),
          createdAt: new Date(),
        });
      form.resetFields();
      setCurrentStep(0);
      onCancel();
      message.success(
        `Reservación guardadá con éxito, número de folio: ${folio}`
      );
    } catch (error) {
      message.error("Error al guardar la reservación.");
      console.error("Error saving reservation: ", error);
    }
  };

  const steps = [
    {
      title: "Información del Cliente",
      content: (
        <Form form={form} layout="vertical" onFinish={handleClientInfoSubmit}>
          <Form.Item
            name="customerName"
            label="Nombre"
            rules={[{ required: true, message: "Por favor ingrese su nombre" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="customerEmail"
            label="Correo Electrónico"
            rules={[
              {
                required: true,
                message: "Por favor ingrese su correo electrónico",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="customerPhone"
            label="Teléfono"
            rules={[
              { required: true, message: "Por favor ingrese su teléfono" },
              {
                pattern: /^[0-9]{12}$/,
                message: "Ingrese un número de teléfono válido (10 dígitos)",
              },
            ]}
          >
            <PhoneInput className="number" country={"mx"} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Siguiente
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Seleccionar Fechas",
      content: (
        <Form form={form} layout="vertical" onFinish={handleDateSelection}>
          <Form.Item
            label="Fechas"
            rules={[
              { required: true, message: "Por favor selecciona las fechas" },
            ]}
          >
            <RangePicker
              disabledDate={(current) =>
                current && current < moment().endOf("day")
              }
              hideDisabledOptions
              onChange={handleDateChange}
              format="DD-MM-YYYY"
            />
          </Form.Item>
          {loading ? (
            <Spin tip="Cargando disponibilidad de habitaciones..." />
          ) : (
            roomAvailability && (
              <>
                {roomAvailability.simple && (
                  <Form.Item
                    name="simpleRooms"
                    label={`Habitaciones Simples (Disponibles: ${roomAvailability.simple.available})`}
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la cantidad de habitaciones simples",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      min={0}
                      max={roomAvailability.simple.available}
                    />
                  </Form.Item>
                )}
                {roomAvailability.doble && (
                  <Form.Item
                    name="dobleRooms"
                    label={`Habitaciones Dobles (Disponibles: ${roomAvailability.doble.available})`}
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la cantidad de habitaciones dobles",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      min={0}
                      max={roomAvailability.doble.available}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  name="adults"
                  label="Número de Adultos"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese el número de adultos",
                    },
                  ]}
                >
                  <Input type="number" min={1} />
                </Form.Item>
                <Form.Item name="children" label="Número de Niños">
                  <Input type="number" min={0} />
                </Form.Item>
              </>
            )
          )}
          <Form.Item>
            <Button onClick={() => setCurrentStep(currentStep - 1)}>
              Anterior
            </Button>
            <Button type="primary" htmlType="submit">
              Siguiente
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Facturación",
      content: (
        <Form form={form} layout="vertical" onFinish={handleIncoiceForm}>
          <Form.Item valuePropName="checked">
            <Checkbox
              value={requiresInvoice}
              onChange={(e) => setRequiresInvoice(e.target.checked)}
            >
              ¿Requiere factura?
            </Checkbox>
          </Form.Item>
          {requiresInvoice === true && (
            <>
              <Form.Item
                name="customerType"
                label="Tipo de Cliente"
                rules={[
                  {
                    required: true,
                    message:
                      "Por favor seleccione si es persona moral o física",
                  },
                ]}
              >
                <Radio.Group onChange={(e) => setCustomerType(e.target.value)}>
                  <Radio value="fisica">Persona Física</Radio>
                  <Radio value="moral">Persona Moral</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="rfc"
                label="RFC"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su RFC para la facturación",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="billingName"
                label={
                  customerType === "moral" ? "Razón Social" : "Nombre Completo"
                }
                rules={[
                  {
                    required: true,
                    message:
                      customerType === "moral"
                        ? "Por favor ingrese su razón social"
                        : "Por favor ingrese su nombre completo",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="billingAddress"
                label="Dirección Fiscal"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su dirección fiscal",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="cfdiUsage"
                label="Uso del CFDI"
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione el uso del CFDI",
                  },
                ]}
              >
                <Select placeholder="Seleccione el uso del CFDI">
                  <Option value="G01">G01 - Adquisición de mercancías</Option>
                  <Option value="G02">
                    G02 - Devoluciones, descuentos o bonificaciones
                  </Option>
                  <Option value="G03">G03 - Gastos en general</Option>
                  <Option value="I01">I01 - Construcciones</Option>
                  <Option value="I02">
                    I02 - Mobiliario y equipo de oficina por inversiones
                  </Option>
                  <Option value="I03">I03 - Equipo de transporte</Option>
                  <Option value="I04">
                    I04 - Equipo de cómputo y accesorios
                  </Option>
                  <Option value="I05">
                    I05 - Dados, troqueles, moldes, matrices y herramental
                  </Option>
                  <Option value="I06">I06 - Comunicaciones telefónicas</Option>
                  <Option value="I07">I07 - Comunicaciones satelitales</Option>
                  <Option value="I08">I08 - Otra maquinaria y equipo</Option>
                  <Option value="D01">
                    D01 - Honorarios médicos, dentales y gastos hospitalarios
                  </Option>
                  <Option value="D02">
                    D02 - Gastos médicos por incapacidad o discapacidad
                  </Option>
                  <Option value="D03">D03 - Gastos funerarios</Option>
                  <Option value="D04">D04 - Donativos</Option>
                  <Option value="D05">
                    D05 - Intereses reales efectivamente pagados por créditos
                    hipotecarios (casa habitación)
                  </Option>
                  <Option value="D06">
                    D06 - Aportaciones voluntarias al SAR
                  </Option>
                  <Option value="D07">
                    D07 - Primas por seguros de gastos médicos
                  </Option>
                  <Option value="D08">
                    D08 - Gastos de transportación escolar obligatoria
                  </Option>
                  <Option value="D09">
                    D09 - Depósitos en cuentas para el ahorro, primas que tengan
                    como base planes de pensiones
                  </Option>
                  <Option value="D10">
                    D10 - Pagos por servicios educativos (colegiaturas)
                  </Option>
                  <Option value="P01">P01 - Por definir</Option>
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Button onClick={() => setCurrentStep(currentStep - 1)}>
              Anterior
            </Button>
            <Button type="primary" htmlType="submit">
              Siguiente
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Resumen",
      content: (
        <>
          <Card title="Resumen de la Reserva">
            <p>
              <strong>Nombre:</strong> {reservationData.customerName}
            </p>
            <p>
              <strong>Correo:</strong> {reservationData.customerEmail}
            </p>
            <p>
              <strong>Teléfono:</strong> {reservationData.customerPhone}
            </p>
            <p>
              <strong>Fechas:</strong>{" "}
              {selectedDates.length > 0 && (
                <>
                  {moment(selectedDates[0].toDate()).format("DD-MM-YYYY")} a{" "}
                  {moment(selectedDates[1].toDate()).format("DD-MM-YYYY")}
                </>
              )}

            </p>
            <p>
              <strong>Habitaciones Simples:</strong>{" "}
              {reservationData.simpleRooms}
            </p>
            <p>
              <strong>Habitaciones Dobles:</strong> {reservationData.dobleRooms}
            </p>
            <p>
              <strong>Adultos:</strong> {reservationData.adults}
            </p>
            <p>
              <strong>Niños:</strong> {reservationData.children}
            </p>
            <p>
              <strong>Subtotal:</strong> {reservationData.subtotal}
            </p>
            <p>
              <strong color="green">Total:</strong> {(reservationData.subtotal + (reservationData.subtotal * .16))}
            </p>
            {requiresInvoice && (
              <>
                <p>
                  <strong>RFC:</strong> {form.getFieldValue("rfc")}
                </p>
                <p>
                  <strong>
                    {customerType === "moral"
                      ? "Razón Social"
                      : "Nombre Completo"}
                    :
                  </strong>{" "}
                  {form.getFieldValue("billingName")}
                </p>
                <p>
                  <strong>Dirección Fiscal:</strong>{" "}
                  {form.getFieldValue("billingAddress")}
                </p>
                <p>
                  <strong>Uso del CFDI:</strong>{" "}
                  {form.getFieldValue("cfdiUsage")}
                </p>
              </>
            )}
          </Card>
          <Form.Item>
            <Button onClick={() => setCurrentStep(currentStep - 1)}>
              Anterior
            </Button>
            <Button type="primary" onClick={handleInfoSelection}>
              Proceder al pago
            </Button>
          </Form.Item>
        </>
      ),
    },
    {
      title: "Pago",
      content: (
        <InitPayment handleSubmit={handleSubmit} reservationData={reservationData} previousStep={previousStep} />
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      title="Reserva"
      onCancel={onCancel}
      footer={null}
      width={950}
    >
      <Steps current={currentStep} style={{ marginBottom: 24 }}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div>{steps[currentStep].content}</div>
    </Modal>
  );
};

export default BookingModal;
