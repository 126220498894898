import React, { useState } from "react";
import { Carousel, Card, Typography } from "antd";
import BookingModal from "./BookingModal";
import EntradaImg from "./imgs/entrada.jpg";
import HotelImg from "./imgs/hotel-entrada.jpg";
import EstacionamientoImg from "./imgs/estacionamiento.jpeg";
import ComedorImg from "./imgs/kitchen.jpg";
import Anuncios from "./Anuncios";

// Estilos
const carouselStyle = {
  margin: 0,
  padding: 0,
  position: "relative",
  height: "80vh",
  opacity: 0.6,
};

const Home = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Carousel autoplay speed={1600} style={carouselStyle}>
        <div>
          <img
            src={HotelImg}
            alt="Hotel Campestre Portada 1"
            style={{ width: "100%", height: "80vh", objectFit: "cover" }}
          />
        </div>
        <div>
          <img
            src={EntradaImg}
            alt="Hotel Campestre Portada 2"
            style={{ width: "100%", height: "80vh", objectFit: "cover" }}
          />
        </div>
        <div>
          <img
            src={ComedorImg}
            alt="Hotel Campestre Portada 3"
            style={{ width: "100%", height: "80vh", objectFit: "cover" }}
          />
        </div>
        <div>
          <img
            src={EstacionamientoImg}
            alt="Hotel Campestre Portada 4"
            style={{ width: "100%", height: "80vh", objectFit: "cover" }}
          />
        </div>
      </Carousel>
      <div className="home">
        <Card bordered={false} style={{ margin: 0, padding: 0, backgroundColor: "rgba(255, 255, 255, 0.4)", color: "#000",}}>
          <Typography.Title
            level={2}
            style={{ marginBottom: "15px" }}
          >
            ¡Hospédate con nosotros y descubre la belleza de Chignahuapan!
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: "16px" }}>
            Nuestro hotel ofrece una ubicación privilegiada cerca de las
            principales atracciones turísticas, lo que te permite disfrutar de
            la naturaleza y la tranquilidad, todo mientras te hospedas en un
            ambiente acogedor y relajante. Ya sea que quieras explorar el centro
            histórico, relajarte en los baños termales, o descubrir las
            majestuosas cascadas, en Rinconcito de Chigna estarás a pocos
            minutos de todo.
          </Typography.Paragraph>
        </Card>
        <Anuncios />
      </div>
      <BookingModal visible={isModalVisible} onCancel={handleCancel} />
    </>
  );
};

export default Home;
