import React, { useState } from "react";
import { Form, Button } from "antd";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

const PaymentForm = ({ handleSubmit, total, folio }) => {
  const [form] = Form.useForm();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const submitPayment = async () => {
    try {
      setLoading(true);

      if (!stripe || !elements) {
        console.error("Stripe.js aún no ha sido cargado.");
        return;
      }

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "",
        },
        redirect: 'if_required',
      });

      if (error) {
        console.error("Error al confirmar el pago:", error);
      } else {
        handleSubmit(folio);
      }
    } catch (error) {
      console.error("Error en la transacción:", error);
    } finally {
      setLoading(false);
    }
  };

  const options = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    }
  };

  return (
    <Form form={form} onFinish={submitPayment}>
      <PaymentElement options={options}/>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={!stripe}
          loading={loading}
        >
          Pagar ${total}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PaymentForm;
